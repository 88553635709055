// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";

function loadMessages(lang, loadMessages$1) {
  var lang$1 = Core__Option.getOr(lang, "en");
  return Promise.all(loadMessages$1(lang$1));
}

export {
  loadMessages ,
}
/* No side effect */
